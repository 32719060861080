.active {
  text-decoration: none;
  color: #093d98;
}

.inactive {
  text-decoration: none;
  color: grey;
}

.inactive:hover span {
  font-weight: 700;
}

.active span {
  font-weight: 700;
}

.active li svg {
  border-bottom: 2px solid #093d98;
  color: #093d98;
}
