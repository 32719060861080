.actionButtons {
  cursor: pointer;
}

.button {
  opacity: 0.5;
}

.button:hover {
  opacity: 1;
}

.deleteIcon {
  color: #b60000;
}

.taskName {
  width: 100%;
}

.ganttTreeIcon {
  margin-right: 4px;
  width: 18px;
}

.muiIcon {
  font-size: 20px;
  vertical-align: middle;
  color: #747474;
}

.sprintIcon {
  color: #65c16f;
}

.storyIcon {
  color: #dce802;
}

.taskIcon {
  color: #3db9d3;
}

.avatar {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
}

.avatarWrapper {
  display: flex;
  align-items: center;
  text-align: left;
}

.avatarWrapper > .avatar,
.avatarWrapper > span {
  margin-right: 4px;
}
