.light {
  color: #ffffff;
  background-color: #222222;
}

.dark {
  background-color: #ffffff;
  color: #222222;
}

.yellow {
  color: #222222;
  background-color: #bfc518;
}

.green {
  color: #ffffff;
  background-color: #65c16f;
}

.blue {
  color: #ffffff;
  background-color: #3db9d3;
}

.orange {
  color: #ffffff;
  background-color: #f6760c;
}

.purple {
  color: #ffffff;
  background-color: #7d04c0;
}

.red {
  color: #ffffff;
  background-color: #b60000;
}

.brown {
  color: #ffffff;
  background-color: #523030;
}

.national {
  color: #edd222;
  background-color: #2f2f91;
}
